@use 'src/app/variables' as *;
@use 'theme-mixins';

:root {
  --white: #ffffff;
  --white-200: #f9f9f9;

  --black: #000000;
  --almost-black: #1f1f1f; // TODO verify and rename

  --gb-100: #f7f8fc;
  --gb-200: #f1f4f9;
  --gb-300: #ecf0f6;
  --gb-400: #dbdde8;
  --gb-500: #bfc3d6;

  --grey-50: #f0f0f0;
  --grey-100: #eaedf0;
  --grey-200: #ddd;
  --grey-300: #757575;
  --grey-400: #5c646f;
  --grey-500: #4d4d4d;
  --grey-600: #000000;
  // ???
  //--grey-600: #181818;
  //--grey-700: #000000;
  --beige: #f6f1f1;

  --green-50: #e8fbee;
  --green-200: #c8ecd7;
  --green-500: #139656;
  --red-50: #fbe1db;
  --red-200: #f7c5ba;
  --red-500: #db2800;
  --orange-50: #fff5e5;
  --orange-200: #f6e2c3;
  --orange-500: #c27400;
  --red-50-dark: #3a201b;
  --red-200-dark: #6c2313;
  --red-500-dark: #db2800;
  --orange-50-dark: #524228;
  --orange-200-dark: #866431;
  --orange-500-dark: #ffb547;
  --green-50-dark: #1e3125;
  --green-200-dark: #1d633f;
  --green-500-dark: #1ad77c;
  --blue-50: #e3effd;
  --blue-200: #b6d6fa;
  --blue-500: #0070f0;
  --blue-50-dark: #1c2838;
  --blue-200-dark: #163760;
  --blue-500-dark: #0a7cff;

  /*
   * OLD palette remnants
   */
  --io-primary: #0d47a1; // TODO extract palette
  --io-primary-hover: #1565c0; // TODO extract palette
  --io-primary-active: var(--io-primary-hover); // TODO extract palette

  /**
   * Backgrounds and layers
   */
  --io-bg-main: var(--white-200);
  --io-bg-disabled: #eeeeee; // TODO palette
  --io-layer-1: var(--gb-100); // Not #f2f2f2 ???
  --io-layer-2: var(--gb-300);
  --io-layer-3: var(--beige);
  --io-layer-4: var(--grey-50);
  --io-layer-5: #f0edf1; // TODO palette
  --io-layer-6: #f3eff5; // TODO palette
  --io-layer-white: var(--white);
  --io-layer-dark: var(--black);
  --io-hover-main: var(--gb-200);

  /**
   * Text colors
   */
  --io-text: var(--almost-black);
  --io-text-dimmed-700: var(--grey-400);
  --io-text-dimmed-500: var(--grey-300);
  --io-text-disabled: #9f9f9f; // TODO palette
  --io-text-inverse: var(--white-200); // TODO verify
  --io-text-warning: var(--orange-500-dark);

  /**
   * Shadows
   */
  --io-shadow-medium: 0px 1px 4px 0px rgba(0, 0, 0, 0.18);
  --io-shadow-dark: 0px 1px 8px 0px #d7d7d7;

  /**
   * Borders
   */
  --io-border-light: var(--grey-200);
  --io-border-main: var(--gb-400);
  --io-border-dark: var(--gb-500);
  --io-border-disabled: #9f9f9f; // TODO palette, same as text-disabled

  /**
   * Status colors
   */
  --io-success: var(--green-500);
  --io-success-medium: var(--green-200);
  --io-success-faded: var(--green-50);
  --io-danger: var(--red-500);
  --io-danger-medium: var(--red-200);
  --io-danger-faded: var(--red-50);
  --io-warning: var(--orange-500);
  --io-warning-medium: var(--orange-200);
  --io-warning-faded: var(--orange-50);
  --io-info: var(--blue-500);
  --io-info-medium: var(--blue-200);
  --io-info-faded: var(--blue-50);
  // Need Design inputs
  --io-label-faded: #f5f5f5; // TODO see if we can use bg-main
  --io-label-medium: #c3c3c3; // TODO extract palette, see if it's being used

  /**
   * Navigation
   */
  --io-navbar-background: #eaedf0;
  --io-navbar-border: var(--io-border-dark);
  --io-navbar-item-text: #4d4d4d;
  --io-navbar-item-text-hover: #4d4d4d;
  --io-navbar-item-text-active: var(--white);
  --io-navbar-item-background: transparent;
  --io-navbar-item-background-hover: #d6dfed;
  --io-navbar-item-background-active: var(--io-primary);

  /**
   * Components
   */
  --io-button-dark-background: var(--black);
  --io-button-dark-hover: #464646; // TODO extract palette
  --io-button-dark-active: var(--io-button-dark-hover);
  --io-button-dark-text: var(--white);

  --io-button-light-background: var(--white-200);
  --io-button-light-hover: #ededed; // TODO extract palette
  --io-button-light-active: var(--io-button-light-hover);
  --io-button-light-text: var(--black);
  --io-button-light-border: var(--io-button-light-hover);
  --io-button-light-border-hover: #e8e8e8; // TODO extract palette

  --io-button-danger-background: var(--io-danger);
  --io-button-danger-hover: #b82100; // TODO extract palette
  --io-button-danger-active: var(--io-button-danger-hover);
  --io-button-danger-text: var(--white);

  --io-button-danger-light-text: var(--io-danger);

  --io-button-border-blue: var(--blue-500);

  --io-toggle-button-background: #f5f5f5; // TODO extract palette
  --io-toggle-button-border: #cccccc; // TODO extract palette
  --io-toggle-button-text: var(--almost-black);
  --io-toggle-button-border-hover: var(--almost-black);
  --io-toggle-button-text-active: var(--black);
  --io-toggle-button-border-active: var(--black);

  --io-icon-button-transparent-fill: var(--io-text);
  --io-icon-button-transparent-bg: transparent;
  --io-icon-button-transparent-bg-hover: #ededed; // TODO extract palette
  --io-icon-button-light-fill: var(--io-text);
  --io-icon-button-light-bg: #ededed; // TODO extract palette
  --io-icon-button-light-bg-hover: #e0e0e0; // TODO extract palette
  --io-icon-button-solid-light-fill: var(--black);
  --io-icon-button-solid-light-bg: var(--white);
  --io-icon-button-solid-light-bg-hover: #ededed; // TODO extract palette
  --io-icon-button-dark-fill: var(--io-button-dark-text);
  --io-icon-button-dark-bg: var(--io-button-dark-background);
  --io-icon-button-dark-bg-hover: var(--io-button-dark-hover);

  --io-input-text: var(--io-text);
  --io-input-background: var(--white);
  --io-input-border-color: #8692a6; // same as border-dark in dark mode
  --io-input-border-color-hover: var(--black);
  --io-input-border-color-focus: var(--io-primary);
  --io-input-placeholder-color: var(--io-text-dimmed-500);
  --io-input-height: #{toRem(44)};
  --io-input-large-height: #{toRem(60)};

  --io-avatar-size-small: #{toRem(28)};
  --io-avatar-size-large: #{toRem(36)};
  --io-avatar-size-huge: #{toRem(64)};
  --io-avatar-size-nav-bar: #{toRem(24)};

  --io-list-header-bg: #5c646f; // TODO  new gray-400
  --io-list-header-text: var(--white);

  --io-heading-border-color: var(--io-border-main);

  --io-select-background: var(--io-input-background);
  --io-select-border: var(--io-input-border-color);
  --io-select-input-text: var(--io-input-text);
  --io-select-option-text: var(--io-text);
  --io-select-option-background: var(--gb-300);
  --io-select-border-focus: var(--io-input-border-color-focus);

  --io-vault-card-bg: var(--io-layer-2);

  --io-section-card-bg: var(--io-layer-white);
  --io-section-card-shadow: var(--io-shadow-medium);
  --io-section-card-border-radius: 8px;
  --io-section-card-padding: var(--io-spacing-9);

  --io-progressbar-background: #ededed;
  --io-progressbar-progress: var(--io-primary);
  --io-progressbar-progress-gradient: linear-gradient(270deg, #0057ff 0%, #0d47a1 100%);
  --io-progressbar-progress-gradient-beyond: #0057ff;

  --io-tooltip-bg: var(--io-layer-dark);
  --io-tooltip-text: var(--io-text-inverse);

  --io-radio-button-bg: var(--gb-200);

  --io-wallet-connect-button-color: #0d46a2;
  --io-wallet-connect-button-color-hover: #f8f8f8;

  --io-dapp-iframe-button-color: #dfeaff;
  --io-dapp-iframe-button-color-hover: #d5e0f5;

  /**
   * Typography
   *
   * The --font-io var will be provided by nextjs
   */
  --font: BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono', 'Roboto Mono', 'Oxygen Mono',
    'Ubuntu Monospace', 'Source Code Pro', 'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;

  --io-font-size-base: #{toRem(14)};
  --io-font-size-large: #{toRem(16)};
  --io-font-size-small: #{toRem(12)};
  --io-font-size-label: #{toRem(13)};
  --io-font-size-heading-1: #{toRem(42)};
  --io-font-size-heading-2: #{toRem(35)};
  --io-font-size-heading-3: #{toRem(30)};
  --io-font-size-heading-4: #{toRem(24)};
  --io-font-size-heading-5: #{toRem(20)};
  --io-font-size-heading-6: #{toRem(16)};
  --io-font-size-subheading-1: #{toRem(16)};
  --io-font-size-subheading-2: #{toRem(15)};
  --io-font-size-section-section-heading: #{toRem(20)};

  --io-line-height-body: 1.5;
  --io-line-height-heading: 1.2;

  /**
   * Spacing
   */
  --io-spacing-0_5: #{toRem(2)};
  --io-spacing-1: #{toRem(4)};
  --io-spacing-2: #{toRem(8)};
  --io-spacing-3: #{toRem(12)};
  --io-spacing-4: #{toRem(16)};
  --io-spacing-5: #{toRem(20)};
  --io-spacing-6: #{toRem(24)};
  --io-spacing-8: #{toRem(32)};
  --io-spacing-9: #{toRem(36)};
  --io-spacing-10: #{toRem(40)};
  --io-spacing-12: #{toRem(48)};
  --io-spacing-14: #{toRem(56)};
  --io-spacing-16: #{toRem(64)};
  --io-spacing-20: #{toRem(80)};

  /**
   * Avatars and Jazzicons
   */
  @include theme-mixins.avatar-colors;
  @include theme-mixins.jazzicon-colors;
}

@media false and (prefers-color-scheme: dark) {
  :root {
    --white-dark: #343438; // Not sure what to do with this
    --white-200-dark: #181818; // Not sure what to do with this

    --io-bg-main: var(--white-200-dark);
    --io-bg-disabled: #383838;
    --io-layer-1: #272a2e;
    --io-layer-2: #272a2e;
    --io-layer-3: #272a2e;
    --io-layer-4: #272a2e;
    --io-layer-white: var(--white-dark);
    --io-layer-dark: var(--white-dark);

    --io-hover-main: #313540;

    --io-text: var(--white-200);
    --io-text-dimmed-500: #9e9e9e;
    --io-text-dimmed-700: #aab3be;
    --io-text-disabled: #868686;

    --io-border-main: #576377;
    --io-border-light: #505050;
    --io-border-dark: #8692a6;
    --io-border-disabled: #383838;

    /**
     * Status
     */
    --io-success: var(--green-500-dark);
    --io-success-medium: var(--green-200-dark);
    --io-success-faded: var(--green-50-dark);
    --io-danger: var(--red-500-dark);
    --io-danger-medium: var(--red-200-dark);
    --io-danger-faded: var(--red-50-dark);
    --io-warning: var(--orange-500-dark);
    --io-warning-medium: var(--orange-200-dark);
    --io-warning-faded: var(--orange-50-dark);
    --io-info: var(--blue-500-dark);
    --io-info-medium: var(--blue-200-dark);
    --io-info-faded: var(--blue-50-dark);
    // Need Design inputs
    --io-label-faded: #878787;
    --io-label-medium: #c3c3c3;

    /**
     * Navbar
     */
    --io-navbar-background: var(--io-background-dimmed);
    --io-navbar-border: var(--io-border-dark);
    --io-navbar-item-text: #868686;
    --io-navbar-item-text-hover: var(--white);
    --io-navbar-item-text-active: var(--white);
    //--io-navbar-item-background: transparent;
    --io-navbar-item-background-hover: var(--io-list-hover);
    //--io-navbar-item-background-active: var(--io-primary);

    /**
     * Components
     */
    --io-button-dark-hover: #2f2f2f;

    --io-button-light-background: #343438;
    --io-button-light-hover: #4b4b4b;
    --io-button-light-text: var(--white-200);
    --io-button-light-border-hover: #525252;

    --io-input-background: var(--black);
    --io-input-border-color: var(--io-border-dark);
    --io-input-border-color-hover: var(--io-white);
    --io-input-border-color-focus: #1366e8;

    --io-select-background: var(--black);
    --io-select-input-text: var(--white-200);
    --io-select-option-text: var(--white-200);
    --io-select-option-background: #464646;

    --io-tooltip-bg: #8692a6;
  }
}
