@use 'src/app/variables' as *;

*,
* > * {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  min-height: 100%;
  margin: 0;
  max-width: 100vw; /* TODO: revisit */
  overflow-x: hidden;
}

body {
  color: var(--io-text);
  background: var(--io-bg-main);
  font-family: var(--font);
  font-size: var(--io-font-size-base);
  line-height: var(--io-line-height-body);
}

/**
 * Fixes to prevent content to be displaced by radix-ui dropdown components
 * @see {@link https://github.com/radix-ui/primitives/issues/1925}
 */
body[style] {
  margin: 0 auto !important;
}
body[data-scroll-locked] {
  overflow: hidden;
  position: fixed;
  width: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

@media false and (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

/**
 * Layout variables
 */
:root {
  --sidebar-size: #{toRem(72)};
  --sidebar-link-icon-size: #{toRem(20)};
  --top-bar-size: #{toRem(64)};
  --nav-button-size: #{toRem(15)};

  // Override io-kit
  --io-avatar-size-nav-bar: var(--sidebar-link-icon-size);

  @media (min-width: $viewport-lg) {
    --sidebar-link-icon-size: #{toRem(24)};
    --nav-button-size: #{toRem(20)};
    --sidebar-size: #{toRem(215)};
  }
}
