@mixin avatar-colors {
  --io-avatar-color-1: #9dc6e1;
  --io-avatar-color-2: #b4d4ff;
  --io-avatar-color-3: #e4c59e;
  --io-avatar-color-4: #d8ccca;
  --io-avatar-color-5: #bed7dc;
  --io-avatar-color-6: #ded9fb;
  --io-avatar-color-7: #c9d4e9;
  --io-avatar-color-virtual-signer: #d9742b;
  --io-avatar-text-1: #000;
  --io-avatar-text-2: #000;
  --io-avatar-text-3: #000;
  --io-avatar-text-4: #000;
  --io-avatar-text-5: #000;
  --io-avatar-text-6: #000;
  --io-avatar-text-7: #000;
  --io-avatar-text-virtual-signer: #fff;
}

@mixin jazzicon-colors {
  --io-jazz-color-1: #8e81dd;
  --io-jazz-color-2: #0d47a1;
  --io-jazz-color-3: #9dc6e1;
  --io-jazz-color-4: #636f99;
  --io-jazz-color-5: #8692a6;
  --io-jazz-color-6: #dfe4ea;
  --io-jazz-color-7: #f3f1e9;
}
